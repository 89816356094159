<h1 mat-dialog-title>{{ data.title }}</h1>
<div mat-dialog-content>
  <!-- Render the description as HTML -->
  <div [innerHTML]="description"></div>
</div>

<div mat-dialog-actions>
  <button *ngIf="!data.hideCancel" mat-button (click)="onNoClick()">{{ 'generic.cancel' | translate | uppercase }}</button>
  <span class="spacer"></span>
  <button mat-button (click)="confirm()" color="primary" cdkFocusInitial>{{ 'generic.ok' | translate }}</button>
</div>
