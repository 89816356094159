<ng-container *ngIf="(done$ | async) === false; else done">
  <h1>{{ 'review_input.title' | translate }}</h1>

  <app-review-activity-description [activity]="(item$ | async)"></app-review-activity-description>
  <hr/>

  <h1>{{ 'review_input.rating.title' | translate }}</h1>
  <div fxFlex fxLayoutGap="16px" fxLayoutAlign="center center" style="width: 100%">
    <div>
      <div *ngFor="let input of ratingInputs" >
        <h3>{{input.name}}</h3>
        <app-review-rating height="40px" [editable]="true" [(value)]="input.value" (valueChange)="calculateTotal()">
        </app-review-rating>
      </div>
    </div>

    <div fxFlex fxLayout="column" fxLayoutAlign="start end">
      <h3>{{ 'review_input.average_rating' | translate }}</h3>
      <h1 class="xl-rating">{{ (currentRating / 20) | i18n_number: '1.1-1' | async }}</h1>
      <app-review-rating height="25px" [editable]="false" [(value)]="currentRating"></app-review-rating>
    </div>

  </div>


  <form [formGroup]="reviewForm" fxLayout="column">
    <div fxLayout="column" fxLayoutGap="8px">
      <span class="disclaimer">
        {{ 'review_input.character_count' | translate: {
          characterCount: reviewForm.value.reviewContent?.length ?? 0,
          maxChars } }}
      </span>
      <mat-form-field appearance="outline">
        <mat-label>{{ 'review_input.content.label' | translate }}</mat-label>
        <textarea
          [maxlength]="maxChars"
          rows="6"
          matInput
          formControlName="reviewContent"
          [placeholder]="'review_input.content.placeholder' | translate"
        ></textarea>
      </mat-form-field>

      <span class="disclaimer">
        {{ 'review_input.character_count' | translate: {
          characterCount: reviewForm.value.reviewTips?.length ?? 0,
          maxChars } }}
      </span>
      <mat-form-field appearance="outline">
        <mat-label>{{ 'review_input.tips.label' | translate }}</mat-label>
        <textarea
          [maxlength]="maxChars"
          rows="3"
          matInput
          formControlName="reviewTips"
          [placeholder]="'review_input.tips.placeholder' | translate"
        ></textarea>
      </mat-form-field>
      <span class="disclaimer">{{ 'review_input.tips.disclaimer' | translate }}</span>
    </div>

    <p>{{ 'review_input.job_title.title' | translate }}</p>

    <div fxFlex fxLayoutAlign="space-between center" class="function-inputs">
      <mat-form-field appearance="outline" [style.width.%]="reviewForm.value.position === EJobTitle.OTHER ? 45 : 100">
        <mat-label>{{ 'review_input.job_title.label' | translate }}</mat-label>
        <mat-select formControlName="position" (selectionChange)="functionChange()">
          <mat-option *ngFor="let jobTitle of EJobTitle | keyvalue: originalOrder" [value]="jobTitle.value">
            {{ ('review.job_title.' + jobTitle.value) | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" *ngIf="reviewForm.get('position').value === EJobTitle.OTHER" [style.width.%]="50">
        <!-- The required asterisk is added her manually on purpose because the custom conditional required validator does not trigger it -->
        <mat-label>{{ 'review_input.job_title.description.label' | translate }}*</mat-label>
        <input [maxlength]="maxJobtitleChars" matInput formControlName="positionElaboration"/>
      </mat-form-field>
    </div>

    <p>{{ 'review_input.age_group.title' | translate }}</p>

    <mat-form-field appearance="outline" *ngIf="(ageGroups$ | async)?.length > 0">
      <mat-label>{{ 'review_input.age_group.label' | translate }}</mat-label>
      <mat-select formControlName="ageGroup">
        <mat-option *ngFor="let group of (ageGroups$ | async)" [value]="group.id">{{group.name}}</mat-option>
      </mat-select>
    </mat-form-field>

    <button color="primary" mat-flat-button (click)="submit()" [disabled]="reviewForm.invalid || reviewForm.pristine">
      {{ 'review_input.submit' | translate }}
    </button>
  </form>
</ng-container>
<ng-template #done>
  <div fxFlex fxLayoutAlign="center center" fxFill>
    <div fxFlex fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="16px">
      <div fxFlex fxLayoutAlign="end center">
        <img width="128px" src="../../../../assets/images/party-popper.gif"/>
        <h1>{{ 'review_input.thanks' | translate }}</h1>
        <img fxHide.sm [fxHide.xl]="false" width="128px" src="../../../../assets/images/party-popper.gif" [style.transform]="'scaleX(-1)'"/>
      </div>

      <h3>{{ 'review_input.will_be_shown_soon' | translate }}</h3>
    </div>
  </div>
  <h1>{{ 'review_input.preview.title' | translate }}</h1>
  <app-review-preview *ngIf="currentReview$ | async as review" [review]="review"></app-review-preview>
  <button (click)="goBack()" color="primary" mat-flat-button>
    <mat-icon>chevron_left</mat-icon>
    {{ 'review_input.back' | translate }}
  </button>
</ng-template>
