import { ActivityReviewService } from 'src/api/activity/activity-review/activity-review.service';
import { ProgramProvider } from 'src/providers/program.provider';
import {
  animate, AnimationBuilder, group, query, style,
} from '@angular/animations';
import {
  AfterViewInit, Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild, ViewEncapsulation,
} from '@angular/core';
import { MatSidenav, MatSidenavContent } from '@angular/material/sidenav';
import { debounce } from 'lodash';
import {
  BehaviorSubject, combineLatest, firstValueFrom, Observable, of, Subject,
} from 'rxjs';
import {
  debounceTime, filter, map, startWith, switchMap, take, takeUntil,
} from 'rxjs/operators';
import { easing, routerTransition } from 'src/animations';
import { DoenKidsGenericApiProvider } from 'src/providers/generic.provider';
import { PermissionProvider } from 'src/providers/permission.provider';
import { ScreenSizeProvider } from 'src/providers/screen-size.provider';
import { DoenkidsSessionProvider } from 'src/providers/session.provider';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { NewsItemService } from 'src/api/customer/news-item/news-item.service';
import { NewsfeedProvider } from 'src/providers/newsfeed-provider';
import { OrganizationUnitTypeNamePipe } from 'src/pipes/organization-unit-type-name';
import { TranslateService } from 'src/app/utils/translate.service';
import { ActivityToReviewCountProvider } from 'src/providers/activity-to-review-count.provider';
import { IOrganizationUnitOverview } from 'typings/doenkids/doenkids';
import { SupportProvider } from 'src/providers/support.provider';
import { environment } from 'src/environments/environment';

interface ISideNavItem {
  isEnabled$: Observable<boolean>;
  name: string;
  nameParams?: { [key: string]: string };
  icon: string;
  routerLink?: string;
  url?: string;
  badgeValue$?: Observable<number | string>;
}

export const openMenu: Subject<void> = new Subject<void>();

const tagName = 'app-split-pane';

@Component({
  selector: tagName,
  templateUrl: './split-pane.component.html',
  styleUrls: ['./split-pane.component.scss'],
  animations: [routerTransition],
  encapsulation: ViewEncapsulation.None,
})
export class SplitPaneComponent implements OnInit, AfterViewInit, OnDestroy {
  public drawerClosed: boolean;

  /** References to the content */
  @ViewChild(MatSidenavContent, { read: ElementRef }) matSideNavContent: ElementRef;

  /** Drawer */
  @ViewChild(MatSidenav, { read: ElementRef }) drawerEl: ElementRef;

  @ViewChild(MatSidenav) drawer: MatSidenav;

  isXSmall$: Observable<boolean>;

  isAdmin$: Observable<boolean>;

  hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$: Observable<boolean>;

  isDoenkidsManagement$: Observable<boolean>;

  newsItemCount$: BehaviorSubject<number>;

  unpublishedProgramCount$: Observable<number>;

  reviewNewCount$ = new BehaviorSubject(0);

  activityNewCount$: BehaviorSubject<number>;

  programTemplateNewCount$ = new BehaviorSubject(0);

  currentOUDetails$: Observable<IOrganizationUnitOverview>;

  isOrganizationOfTypeCustomer$: Observable<boolean>;

  isOrganizationOfTypeGroup$: Observable<boolean>;

  isOrganizationOfTypeLocation$: Observable<boolean>;

  isRootOrganizationUnit$: Observable<boolean>;

  buildVersion?: string;

  stop$ = new Subject();

  private HORIZIONTAL_HIDDEN_DRAWER_PERCENTAGE = 0.7;

  routes$: Observable<ISideNavItem[]>;

  translationsHaveLoaded$ = new BehaviorSubject<boolean>(false);

  private debouncedSetHeight = debounce(this.setHeight, 300);

  public logoUrl = environment.auth0Tennant.logoUrl;

  public poweredByLogo = environment.poweredByLogo;

  public showLogo = environment.showLogoAboveNavigation;

  constructor(
    private $screenSize: ScreenSizeProvider,
    private $session: DoenkidsSessionProvider,
    private $permission: PermissionProvider,
    private programProvider: ProgramProvider,
    private $newsItem: NewsItemService,
    private $review: ActivityReviewService,
    private newsfeedProvider: NewsfeedProvider,
    private animationBuilder: AnimationBuilder,
    private zone: NgZone,
    private genericAPI: DoenKidsGenericApiProvider,
    private $translateService: TranslateService,
    private organizationUnitTypeNamePipe: OrganizationUnitTypeNamePipe,
    private $activityToReviewCountService: ActivityToReviewCountProvider,
    private $support: SupportProvider,
  ) {
    this.isXSmall$ = this.$screenSize?.isXSmall$;
    this.isAdmin$ = this.$session.isAdmin$;

    this.hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$ = this.$permission.hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$.pipe(takeUntil(this.stop$));

    this.isDoenkidsManagement$ = this.$permission?.isDoenkidsManagement$?.pipe(takeUntil(this.stop$));

    this.unpublishedProgramCount$ = combineLatest([this.$session.ouCountryCode$, this.$permission.canSeeUnpublishedProgramCountPermission$, this.programProvider?.unpublishedProgramCount$])
      .pipe(
        takeUntil(this.stop$),
        map(([ouCountryCode, canSeeUnpublishedProgramCount, unpublishedPrograms]) => ouCountryCode.toLowerCase() === 'nl' && canSeeUnpublishedProgramCount ? unpublishedPrograms.count : 0),
      );

    this.currentOUDetails$ = this.$session.getOrganizationUnit$.pipe(
      takeUntil(this.stop$),
    );

    this.isOrganizationOfTypeCustomer$ = this.$session.isCurrentOrganizationUnitIsOfTypeCustomer$.pipe(takeUntil(this.stop$));

    this.isOrganizationOfTypeGroup$ = this.$session.isCurrentOrganizationUnitIsOfTypeGroup$.pipe(takeUntil(this.stop$));

    this.isOrganizationOfTypeLocation$ = this.$session.isCurrentOrganizationUnitIsOfTypeLocation$.pipe(takeUntil(this.stop$));

    this.isRootOrganizationUnit$ = this.$session.isRootOrganization$.pipe(takeUntil(this.stop$));

    this.activityNewCount$ = new BehaviorSubject(0);

    this.newsItemCount$ = new BehaviorSubject(0);

    this.routes$ = combineLatest([
      this.currentOUDetails$,
      this.$translateService.onInitialTranslationAndLangOrTranslationChange$,
    ]).pipe(
      takeUntil(this.stop$),
      map(([organization]) => {
        const organizationUnitDisplayValue = this.$translateService.instant(
          this.organizationUnitTypeNamePipe.transform(organization.organization_unit_type_id),
        );

        const navigation: ISideNavItem[] = [
          {
            isEnabled$: of(true),
            icon: 'filter_none',
            name: _('split_pane.navigation.activities'),
            routerLink: 'activity-overview',
            badgeValue$: combineLatest([this.isAdmin$, this.hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$]).pipe(
              switchMap(([isAdmin, hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree]) => {
                if (isAdmin || hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree) {
                  return this.activityNewCount$.pipe(map((value) => value === 0 ? '' : value));
                }
                return of(null);
              }),
            ),
          },
          {
            isEnabled$: this.isRootOrganizationUnit$,
            icon: 'filter_none',
            name: _('split_pane.navigation.base_activities'),
            routerLink: 'base-activities',
          },
          {
            isEnabled$: of(true),
            icon: 'library_books',
            name: _('split_pane.navigation.examples'),
            badgeValue$: this.isAdmin$.pipe(switchMap((isAdmin) => {
              if (isAdmin) { return this.programTemplateNewCount$.pipe(map((value) => value === 0 ? '' : value)); }
              return of(null);
            })),
            routerLink: `/organization/${organization.id}/templates`,
          },
          {
            isEnabled$: this.isRootOrganizationUnit$,
            icon: 'library_books',
            name: _('split_pane.navigation.base_examples'),
            routerLink: `/organization/${organization.id}/base/templates`,
          },
          {
            isEnabled$: of(true),
            icon: 'calendar_today',
            name: _('split_pane.navigation.calendar'),
            badgeValue$: this.unpublishedProgramCount$.pipe(map((value) => value === 0 ? '' : value)),
            routerLink: 'calendar',
          },
          {
            isEnabled$: this.isOrganizationOfTypeLocation$,
            icon: 'push_pin',
            name: _('split_pane.navigation.pinboard'),
            routerLink: `/organization/${organization.id}/pinbord`,
          },
          {
            isEnabled$: combineLatest([this.$permission.hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$, this.$permission.hasOUWritePermissions$])
              .pipe(map(([hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree, hasWritePermission]) => hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree && hasWritePermission)),
            icon: 'account_box',
            name: _('split_pane.navigation.organization_unit_users'),
            nameParams: { organizationUnitDisplayValue },
            routerLink: `/organization/${organization.id}/users`,
          },
          {
            isEnabled$: combineLatest([this.$session.isReader$, this.isOrganizationOfTypeGroup$, this.$permission.hasOUWritePermissions$])
              .pipe(map(([isReader, isGroup, hasWritePermission]) => !isGroup && (isReader || hasWritePermission))),
            icon: 'domain',
            name: _('split_pane.navigation.organization_unit_overview'),
            nameParams: { organizationUnitDisplayValue },
            routerLink: `/organization/${organization.id}/overview`,
          },
          {
            isEnabled$: of(true),
            icon: 'settings',
            name: _('split_pane.navigation.settings'),
            routerLink: 'settings',
          },
          {
            isEnabled$: combineLatest([this.$permission.hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$, this.$session.isReader$]).pipe(
              map(([hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree, isReader]) => hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree || isReader),
            ),
            icon: 'admin_panel_settings',
            name: _('split_pane.navigation.active_users'),
            routerLink: 'active-users',
          },
          {
            isEnabled$: combineLatest([
              this.isOrganizationOfTypeCustomer$,
              this.$permission.hasAnalyticsPermission$,
              this.$permission.hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$,
            ])
              .pipe(map(([isCustomer, hasAnalyticsPermission, hasWritePermission]) => isCustomer && hasAnalyticsPermission && hasWritePermission)),
            icon: 'insights',
            name: _('split_pane.navigation.insights'),
            routerLink: `/organization/${organization.id}/insights`,
          },
          {
            isEnabled$: of(true),
            icon: 'article',
            name: _('split_pane.navigation.news_items'),
            badgeValue$: this.newsItemCount$.pipe(map((value) => value === 0 ? '' : value)),
            routerLink: '/',
          },
          {
            isEnabled$: combineLatest([
              this.isRootOrganizationUnit$,
              this.$permission.hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$,
              this.$session.isReader$,
            ])
              .pipe(map(([isRootOrganization, hasWritePermission, isReader]) => isRootOrganization && (isReader || hasWritePermission))),
            icon: 'article',
            name: _('split_pane.navigation.base_news_items'),
            routerLink: 'base-news-items',
          },
          {
            isEnabled$: combineLatest([this.isOrganizationOfTypeCustomer$, this.$permission.manageReviewsPermission$])
              .pipe(map(([isCustomer, hasReviewPermission]) => isCustomer && hasReviewPermission)),
            icon: 'reviews',
            name: _('split_pane.navigation.reviews'),
            badgeValue$: this.reviewNewCount$.pipe(map((value) => value === 0 ? '' : value)),
            routerLink: 'review-approval',
          },
          {
            isEnabled$: this.$support.showSupportItems$,
            icon: 'help',
            name: _('split_pane.navigation.help'),
            url: 'https://help-doenkids.kidskonnect.nl/hc/nl',
          },
        ];

        return navigation;
      }),
    );

    openMenu.pipe(takeUntil(this.stop$)).subscribe(() => {
      this.drawer.toggle();
    });

    this.$translateService.onInitialTranslationAndLangOrTranslationChange$.pipe(
      take(1),
    ).subscribe(() => {
      this.translationsHaveLoaded$.next(true);
    });
  }

  async ngOnInit() {
    const buildJson: any = await firstValueFrom(this.genericAPI.getBuildJson());
    this.buildVersion = buildJson.version.replace('-hotfix.', '.');

    combineLatest([this.$session.getOrganizationUnit$, this.newsfeedProvider.itemMarkedAsRead$])
      .pipe(takeUntil(this.stop$))
      .subscribe(() => {
        this.fetchNewsItemCount();
      });

    this.activityNewCount$ = this.$activityToReviewCountService.activityNewCount$;
    this.programTemplateNewCount$ = this.$activityToReviewCountService.programTemplateNewCount$;

    combineLatest([this.$session.getOrganizationUnit$, this.$permission.canSeeUnpublishedProgramCountPermission$])
      .pipe(
        takeUntil(this.stop$),
        debounceTime(300),
      )
      .subscribe(([ou, permission]) => {
        if (permission) {
          this.programProvider.fetchUnpublishedProgramCount(ou.id);
        } else {
          this.programProvider.setUnpublishedCount(0);
        }
      });

    combineLatest([this.$session.getOrganizationUnit$, this.$review.reviewUpdated$.pipe(startWith(''))])
      .pipe(
        takeUntil(this.stop$),
      )
      .subscribe(() => {
        this.fetchReviewNewCount();
      });
  }

  ngOnDestroy(): void {
    document.removeEventListener('resize', this.debouncedSetHeight);
    this.stop$.next(undefined);
  }

  async ngAfterViewInit() {
    await firstValueFrom(this.translationsHaveLoaded$.pipe(filter((loaded) => loaded)));
    // If the drawer was already closed, we want to animate the close event
    //
    if (this.drawerClosed) {
      await firstValueFrom(this.zone.onStable);
      const isSmallScreen = await firstValueFrom(this.isXSmall$);

      // if we are on a small screen we never want to show the side menu. therefore we give a 0 percentage to the functions so we get a 0 value back
      // if we are not on a small screen the if statement above ensures that the drawer is closed and thus we have to show the small version of the sidenav
      // if the drawer isn't closed we don't event get here
      //
      const sideNavContentMarginLeft = this._calculateSideNavContentMargin(!isSmallScreen ? this.HORIZIONTAL_HIDDEN_DRAWER_PERCENTAGE : 0);
      const drawerTranslateX = this._calculateDrawerTranslateXValue(!isSmallScreen ? this.HORIZIONTAL_HIDDEN_DRAWER_PERCENTAGE : 0);
      const listItemPaddingLeft = this._calculateListItemPaddingLeft();

      this._navigationAnimation(0, easing.enter, listItemPaddingLeft, drawerTranslateX, sideNavContentMarginLeft);
    }

    // since the size of the screen can change we subscribe to the isXSmall stream to see if we need to hide sidenav
    // if it is hidden we are showing a button in the menu bar which will let us trigger the showing of the sidenav
    this.isXSmall$?.pipe(debounceTime(300)).subscribe((isXSmall) => {
      if (isXSmall) {
        // so we are on a small screen hide the sidenav
        //
        this._navigationAnimation(0, easing.leave, '0px', 0, 0);
      } else {
        // we got a bigger screen so we need to show the sidenav again. If we have the sidenav in closed mode we want to hide the predetermined percentage of it
        // if the sidenav isn't closed we want to show the sidenav in all its glory
        //
        const sideNavContentMarginLeft = this._calculateSideNavContentMargin(this.drawerClosed ? this.HORIZIONTAL_HIDDEN_DRAWER_PERCENTAGE : 0);
        const drawerTranslateX = this._calculateDrawerTranslateXValue(this.drawerClosed ? this.HORIZIONTAL_HIDDEN_DRAWER_PERCENTAGE : 0);
        const listItemPaddingLeft = this._calculateListItemPaddingLeft();

        this._navigationAnimation(0, easing.enter, listItemPaddingLeft, drawerTranslateX, sideNavContentMarginLeft);
      }
    });

    document.addEventListener('resize', this.debouncedSetHeight);
    this.setHeight();
  }

  async fetchNewsItemCount() {
    const currentOuId = (await firstValueFrom(this.currentOUDetails$))?.id;
    const result = await this.$newsItem.fetchUnreadTotal(currentOuId);

    if (result) {
      this.newsItemCount$.next(result.count);
    }
  }

  async fetchReviewNewCount() {
    const currentOuId = (await firstValueFrom(this.currentOUDetails$))?.id;
    const result = await this.$review.fetchNewCount(currentOuId);

    if (result) {
      this.reviewNewCount$.next(result.count);
    }
  }

  /**
   * This function creates the animation used for the sidenav entering and leaving the view
   * @param animationDuration The amount of time the animation should take
   * @param easingFn The type of animation we are showing. Currently used values are easing.enter and easing.leave
   * @param listItemPaddingLeftNewValue This is a string with that is used to calculate the left padding for the list items in the sidenav.
   * This is 16px for a non-collapsed sidenav and is a calc string to have it on the right side of the sidenav
   * @param drawerTranslateXValue the percentage with which the drawer is moved to the left
   * @param sideNavContentMarginLeft The number of pixels the content of the sidenav should have as padding on the left. This should 0 when its a small screen.
   * about 72px when the sidenav is collapsed. And full width of the sidenav when not a small screen and not collapsed
   */
  _navigationAnimation(
    animationDuration: number = 300,
    easingFn: any,
    listItemPaddingLeftNewValue: string,
    drawerTranslateXValue: number,
    sideNavContentMarginLeft: number,
  ) {
    const animationTiming = `${animationDuration}ms ${easingFn}`;

    const drawerAnimation = this.animationBuilder.build([
      group([
        query(
          '.mdc-list-item__content',
          animate(
            animationTiming,
            style({
              paddingLeft: listItemPaddingLeftNewValue,
            }),
          ),
          { optional: true },
        ),
        animate(
          animationTiming,
          style({
            transform: `translateX(${drawerTranslateXValue}%)`,
          }),
        ),
      ]),
    ]);

    const sideNavContentAnimation = this.animationBuilder.build([
      animate(
        animationTiming,
        style({
          marginLeft: `${sideNavContentMarginLeft}px`,
        }),
      ),
    ]);

    drawerAnimation.create(this.drawerEl.nativeElement as HTMLElement).play();

    sideNavContentAnimation.create(this.matSideNavContent.nativeElement as HTMLElement).play();
  }

  /**
   * This function toggles our drawerClosed variable and calculates the new offsets for our sidenav and triggers the animation
   * @param animationDuration The amount of time the animation should take. defaults to 300 ms
   */
  toggleNavigation(animationDuration = 300) {
    this.drawerClosed = !this.drawerClosed;

    const easingFn = this.drawerClosed ? easing.leave : easing.enter;

    const drawerTranslateXValue = this._calculateDrawerTranslateXValue(this.drawerClosed ? this.HORIZIONTAL_HIDDEN_DRAWER_PERCENTAGE : 0);

    const sideNavMarginLeftNewValue = this._calculateSideNavContentMargin(this.drawerClosed ? this.HORIZIONTAL_HIDDEN_DRAWER_PERCENTAGE : 0);

    const listItemPaddingLeftNewValue = this._calculateListItemPaddingLeft();

    this._navigationAnimation(animationDuration, easingFn, listItemPaddingLeftNewValue, drawerTranslateXValue, sideNavMarginLeftNewValue);
  }

  /**
   * This function calculates the new width of the sidenav. This is the amount that we should pad our content with so it neatly joins the sidenav
   * @param percentageHidden The amount of the sidenav that should be hidden from the user. This should be between 0 and 1.0
   */
  _calculateSideNavContentMargin(percentageHidden: number) {
    let sideNavMarginLeftNewValue = (this.drawerEl.nativeElement as HTMLElement).clientWidth;

    // if we pass a percentage of 0 we want the full width of the sidenav
    // if we pass a percentage get the the full width of the sidenav as defined in the css and
    // calculate the percentage we want to show
    //
    if (percentageHidden !== 0) {
      sideNavMarginLeftNewValue *= 1 - percentageHidden;
    }

    return sideNavMarginLeftNewValue;
  }

  /**
   * We calculate here the amount the sidenav should be moved to the left
   * @param percentageHidden The amount of the sidenav that should be hidden from the user. This should be between 0 and 1.0
   */
  _calculateDrawerTranslateXValue(percentageHidden: number) {
    const drawerTranslateXValue = -(percentageHidden * 100);

    return drawerTranslateXValue;
  }

  /**
   * This function calculated the padding of the list items that are shown in the sidenav.
   * Default this is 16px. If the drawer is closed however we want to align the list items to the right side of the sidenav
   * Therefore we get the full size of the sidenav as defined in the css and subtract the icon size and some padding for the icon
   */
  _calculateListItemPaddingLeft() {
    /** Needs to be a string to use the calc notation later on */
    const listItemPaddingLeft = '0px';

    /**
     * When the drawer isn't closed
     * We will make the padding a 100% minus the width of the icon and some padding, potential improvement is to calculate the icon width
     * rather than assume its 32px
     */
    return !this.drawerClosed ? listItemPaddingLeft : 'calc(100% - 32px)';
  }

  private setHeight() {
    const notSupportedNotifications = document.getElementsByTagName('app-browser-not-supported');
    const notSupportedNotification = notSupportedNotifications?.item(0) as HTMLElement;
    const splitPaneRefs = document.getElementsByClassName('sidenav-wrapper');
    const splitPaneRef = (splitPaneRefs?.item(0) as HTMLElement);
    const sidenavRef = document.getElementById('sidenav-content');

    let splitPaneHeight = '100%';
    let sidenavHeight = '100vh';

    if (notSupportedNotification) {
      const notificationHeight = notSupportedNotification.clientHeight;
      splitPaneHeight = `calc(100% - ${notificationHeight}px)`;
      sidenavHeight = `calc(100vh - ${notificationHeight}px)`;
    }

    if (splitPaneRef) {
      splitPaneRef.style.height = splitPaneHeight;
    }
    if (sidenavRef) {
      sidenavRef.style.height = sidenavHeight;
    }
  }
}
