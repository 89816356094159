import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectorRef,
  Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewEncapsulation,
} from '@angular/core';
import {
  UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import {
  isEmpty, isEqual, isNil, values, groupBy, some, uniq,
  Dictionary,
} from 'lodash';
import {
  BehaviorSubject, combineLatest, firstValueFrom, Observable, Subject,
} from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged, filter, map, takeUntil, tap,
} from 'rxjs/operators';
import { ActivityKindQuery } from 'src/api/generic/activity-kind/activity-kind.query';
import { ActivityTypeQuery } from 'src/api/generic/activity-type/activity-type.query';
import { ActivityService } from 'src/api/activity/activity/activity.service';
import { AgeGroupQuery } from 'src/api/generic/age-group/age-group.query';
import { AreaOfDevelopmentQuery } from 'src/api/generic/area-of-development/area-of-development.query';
import { GroupSizeQuery } from 'src/api/generic/group-size/group-size.query';
import { LocationQuery } from 'src/api/generic/location/location.query';
import { RangeOfDevelopmentQuery } from 'src/api/generic/range-of-development/range-of-development.query';
import { TagListQuery } from 'src/api/activity/tag-list/tag-list.query';
import { AssetService } from 'src/api/media/asset/asset.service';
import { TranslateService } from 'src/app/utils/translate.service';
import { InfoDialogComponent } from 'src/components/dialogs/info-dialog/info-dialog.component';
import { PermissionProvider } from 'src/providers/permission.provider';
import { DoenkidsSessionProvider } from 'src/providers/session.provider';
import {
  IActivityKindListResponse,
  IActivityTypeListResponse, IAgeGroupListResponse, IAreaOfDevelopmentListResponse, IGroupSizeListResponse,
  IKvsObservationPointListResponse,
  ILocationListResponse, IRangeOfDevelopmentListResponse,
  ITagListResponse,
  IWeightedKvsObservationPointDetails,
} from 'typings/api-activity';
import { IAssetUrl, IAttachmentMediaListResponse } from 'typings/api-media';
import {
  IActivity,
  IActivityKind,
  IActivityType,
  IAgeGroup,
  IAgeGroupDetails,
  IAreaOfDevelopment,
  IAttachmentMedia, IGroupSize,
  IKvsMethod,
  IKvsObservationPointDetails,
  ILocation,
  IOrganizationUnitOverview,
  IOrganizationUnitTagAll, IRangeOfDevelopment, IRangeOfDevelopmentDetails,
} from 'typings/doenkids/doenkids';
import { IMediaItem } from 'typings/section-types';
import { KvsMethodQuery } from 'src/api/generic/kvs-method/kvs-method.query';
import { IKvsAreaOfDevelopmentSubsection, IKvsMethodWithObservationPoints } from 'src/api/generic/kvs-method/kvs-method.store';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { AttachmentService } from 'src/api/media/attachment/attachment.service';
import { I18nToastProvider } from 'src/providers/i18n-toast.provider';
import { IUploadResponse } from 'typings/custom-app-types';

_('activity.cover_image.dialog.title');
_('activity.cover_image.dialog.description');
_('activity.attachments.dialog.title');
_('activity.attachments.dialog.description');
_('activity.tags.dialog.title');
_('activity.tags.dialog.description');
_('activity.duration.dialog.title');
_('activity.duration.dialog.description');
_('activity.preparation_time.dialog.title');
_('activity.preparation_time.dialog.description');
_('activity.summary.dialog.title');
_('activity.summary.dialog.description');
_('activity.age_groups.dialog.title');
_('activity.age_groups.dialog.description');
_('activity.activity_kind.dialog.title');
_('activity.activity_kind.dialog.description');
_('activity.area_of_development.dialog.title');
_('activity.area_of_development.dialog.description');

interface IKvsMethodNode {
  code?: string;
  name: string;
  weight?: number;
  instructions?: string;
  children: IKvsMethodNode[];
}

export interface IKvsMethodFlatNode {
  code?: string;
  expandable: boolean;
  name: string;
  level: number;
  disabled: boolean;
}

@Component({
  selector: 'app-activity-controls',
  templateUrl: './activity-controls.component.html',
  styleUrls: ['./activity-controls.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ActivityControlsComponent implements OnInit, OnDestroy {
  public form: UntypedFormGroup;

  private activityId$: BehaviorSubject<number> = new BehaviorSubject<number>(null);

  /** The currently opened step on the expansion panel accordion.
  */
  step = 0;

  /** Subject that will emit when this component gets destroyed.
   */
  private destroy$: Subject<any> = new Subject();

  private countryCodeChange$ = new Subject<void>();

  @Input()
  public activityDetails$: Observable<IActivity>;

  @Output() updated = new EventEmitter<void>();

  @Output() initialized = new EventEmitter<UntypedFormGroup>();

  @Output() attachmentsUpdated = new EventEmitter<void>();

  public activityAreaOfDevelopment$: BehaviorSubject<IAreaOfDevelopment[]> = new BehaviorSubject<IAreaOfDevelopment[]>([]);

  public allAreaOfDevelopment$: Observable<IAreaOfDevelopment[]>;

  public activityTags$: BehaviorSubject<IOrganizationUnitTagAll[]> = new BehaviorSubject<IOrganizationUnitTagAll[]>([]);

  public allTags$: Observable<IOrganizationUnitTagAll[]>;

  public activityAgeGroups$: BehaviorSubject<IAgeGroup[]> = new BehaviorSubject<IAgeGroup[]>([]);

  public allAgeGroups$: Observable<IAgeGroupDetails[]>;

  public availableAgeGroups$: Observable<IAgeGroupDetails[]>;

  public ageGroupsSelected$: Observable<boolean>;

  public activityActivityKinds$: BehaviorSubject<IActivityKind[]> = new BehaviorSubject<IActivityKind[]>([]);

  public allActivityKinds$: Observable<IActivityKind[]>;

  public activityActivityTypes$: BehaviorSubject<IActivityType[]> = new BehaviorSubject<IActivityType[]>([]);

  public allActivityTypes$: Observable<IActivityType[]>;

  public showKvsMethods$: Observable<boolean>;

  public availableActivityTypes$: Observable<IActivityType[]>;

  public activityGroupSizes$: BehaviorSubject<IGroupSize[]> = new BehaviorSubject<IGroupSize[]>([]);

  public allGroupSizes$: Observable<IGroupSize[]>;

  public activityLocations$: BehaviorSubject<ILocation[]> = new BehaviorSubject<ILocation[]>([]);

  public allLocations$: Observable<ILocation[]>;

  public activityRangesOfDevelopment$: BehaviorSubject<IRangeOfDevelopment[]> = new BehaviorSubject<IRangeOfDevelopment[]>([]);

  public allRangesOfDevelopment$: Observable<IRangeOfDevelopmentDetails[]>;

  public availableRanges$: Observable<IRangeOfDevelopmentDetails[]>;

  public activityAttachments$: BehaviorSubject<IAttachmentMedia[]> = new BehaviorSubject<IAttachmentMedia[]>([]);

  public currentOU$: Observable<IOrganizationUnitOverview>;

  public currentOUId$: Observable<number>;

  public coverImage$: Observable<IMediaItem>;

  public allowedToEdit$: Observable<boolean>;

  public activityObservationPoints$: BehaviorSubject<IWeightedKvsObservationPointDetails[] | null> = new BehaviorSubject<IWeightedKvsObservationPointDetails[] | null>(null);

  public allObservationPoints$: BehaviorSubject<IKvsObservationPointDetails[]> = new BehaviorSubject<IKvsObservationPointDetails[]>([]);

  public kvsToAssign$ = new BehaviorSubject<number>(100);

  public isAdmin$: Observable<boolean>;

  public hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$: Observable<boolean>;

  private allKvsMethods$: Observable<IKvsMethodWithObservationPoints[]>;

  private filteredKvsMethods$: Observable<IKvsMethodWithObservationPoints[]>;

  public kvsMethodsToShow$: Observable<IKvsMethodWithObservationPoints[]>;

  private _transformer = (node: IKvsMethodNode, level: number) => ({
    expandable: !isEmpty(node.children),
    name: node.name,
    level,
    code: node.code,
    weight: node.weight,
    instructions: node.instructions,
    disabled: node.children.length !== 0,
  });

  isExpandable = (node: IKvsMethodFlatNode) => node.expandable;

  // eslint-disable-next-line @typescript-eslint/no-shadow
  hasChild = (_: number, node: IKvsMethodFlatNode) => node.expandable;

  // tslint:disable-next-line:member-ordering
  treeControl = new FlatTreeControl<IKvsMethodFlatNode>((node) => node.level, (node) => node.expandable);

  // tslint:disable-next-line:member-ordering
  treeFlattener = new MatTreeFlattener(this._transformer, (node) => node.level, (node) => node.expandable, (node) => values(node.children));

  // tslint:disable-next-line:member-ordering
  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  /** The selection for checklist */
  // tslint:disable-next-line:member-ordering
  checklistSelection = new SelectionModel<IKvsMethodFlatNode>(true /* multiple */);

  activeKvsControlCount$ = new BehaviorSubject<number>(0);

  activeKvsMethodNames$ = new BehaviorSubject<Dictionary<any[]>>({});

  kvsGroupChange$ = new Subject<void>();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    public fb: UntypedFormBuilder,
    private activityService: ActivityService,
    private tagListQuery: TagListQuery,
    private ageGroupQuery: AgeGroupQuery,
    private activityKindQuery: ActivityKindQuery,
    private activityTypeQuery: ActivityTypeQuery,
    private groupSizeQuery: GroupSizeQuery,
    private locationQuery: LocationQuery,
    private areaOfDevelopmentQuery: AreaOfDevelopmentQuery,
    private rangeOfDevelopmentQuery: RangeOfDevelopmentQuery,
    private kvsMethodQuery: KvsMethodQuery,
    private $permission: PermissionProvider,
    private $session: DoenkidsSessionProvider,
    private assetService: AssetService,
    private dialog: MatDialog,
    private $translateService: TranslateService,
    private $attachment: AttachmentService,
    private $i18nToast: I18nToastProvider,
    private cd: ChangeDetectorRef,
  ) {
    this.form = this.fb.group({
      name: ['', Validators.required],
      duration: [15, Validators.required],
      preparation: [15, Validators.required],
      activityTypes: [],
      subtitle: [''],
      summary: ['', Validators.required],
      location: [[]],
      media_uuid: [''],
      attachments: [[]],
      agegroups: [[]],
      groupsizes: [[]],
      activitykinds: [[]],
      developmentareas: [[]],
      developmentranges: [[]],
      tags: [[]],
      kvsMethods: fb.group({}),
    });

    this.allTags$ = this.tagListQuery.selectAll();

    this.allowedToEdit$ = this.$permission.hasOUWritePermissions$.asObservable();

    this.isAdmin$ = this.$session.isAdmin$;

    this.hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$ = this.$permission.hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$;

    this.ageGroupsSelected$ = this.activityAgeGroups$.pipe(
      map((ageGroups) => {
        const ageGroupIds = ageGroups.map((ageGroup) => ageGroup.id);
        const ageGroupCountryCodes = uniq(ageGroups.map((ageGroup) => ageGroup.country_code));
        const isRelevantGroupSelected = ageGroupCountryCodes.some((countryCode) => countryCode !== 'NL') || [4, 5, 6].some((relavantAgeGroupId) => ageGroupIds.includes(relavantAgeGroupId));
        return isRelevantGroupSelected;
      }),
    );

    this.currentOU$ = this.$session.getOrganizationUnit$.pipe(
      takeUntil(this.destroy$),
    );

    this.currentOUId$ = this.currentOU$.pipe(
      map((details) => details.id),
    );
  }

  async ngOnInit() {
    // Subscribe to the current activity.
    //
    this.activityDetails$.pipe(
      filter((value) => !isNil(value)),
      distinctUntilChanged(),
      takeUntil(this.destroy$),
    ).subscribe((activity: IActivity) => {
      this.activityId$.next(activity.id);
      // Set all the form values.
      //
      this.form.patchValue({
        name: activity.name,
        subtitle: activity.subtitle,
        summary: activity.summary,
        media_uuid: activity.media_uuid,
        duration: activity.duration,
        preparation: activity.preparation,
      });

      // set current values to the form
      //
      this.fetchActivityKinds();
      this.fetchActivityAreasOfDevelopment();
      this.fetchActivityDevelopmentRanges();
      this.fetchActivityGroupSizes();
      this.fetchActivityAgeGroups();
      this.fetchActivityTypes();
      this.fetchActivityLocations();
      this.fetchActivityTags();
      this.fetchActivityAttachments();
    });

    this.activityDetails$.pipe(
      takeUntil(this.destroy$),
      map((activityDetails) => activityDetails.country_code),
      distinctUntilChanged(isEqual),
    ).subscribe(async (countryCode) => {
      countryCode = countryCode?.toUpperCase() ?? 'NL';
      this.countryCodeChange$.next();

      this.allActivityTypes$ = this.activityTypeQuery.getActivityTypeByCountryCodeStream(countryCode).pipe(takeUntil(this.countryCodeChange$));
      this.allAgeGroups$ = this.ageGroupQuery.getAgeGroupsForCountryCode(countryCode).pipe(takeUntil(this.countryCodeChange$));
      this.allActivityKinds$ = this.activityKindQuery.getActivityKindForCountryCode(countryCode).pipe(takeUntil(this.countryCodeChange$));
      this.allLocations$ = this.locationQuery.getLocationForCountryCode(countryCode).pipe(takeUntil(this.countryCodeChange$));
      this.allRangesOfDevelopment$ = this.rangeOfDevelopmentQuery.getRangeOfDevelopmentForCountryCode(countryCode).pipe(takeUntil(this.countryCodeChange$));
      this.allAreaOfDevelopment$ = this.areaOfDevelopmentQuery.getAreaOfDevelopmentForCountryCode(countryCode).pipe(takeUntil(this.countryCodeChange$));
      this.allGroupSizes$ = this.groupSizeQuery.getGroupSizesForCountryCode(countryCode).pipe(
        takeUntil(this.countryCodeChange$),
        map((groupSizes) => groupSizes.sort((group1, group2) => group1.order - group2.order)),
      );
      this.allKvsMethods$ = this.kvsMethodQuery.getKvsMethodsForCountryCode(countryCode).pipe(takeUntil(this.countryCodeChange$));

      this.availableActivityTypes$ = combineLatest([this.allActivityTypes$, this.currentOU$]).pipe(
        map(([activityTypes, organization]) => activityTypes.filter((activityType) => (organization?.activity_types ?? []).includes(activityType.name))),
      );

      this.availableAgeGroups$ = combineLatest([this.allAgeGroups$, this.allActivityTypes$]).pipe(
        takeUntil(this.countryCodeChange$),
        map(([ageGroups, activityTypes]) => {
          const filteredAgeGroupsForActivityTypes = ageGroups.filter((ageGroup) => (ageGroup.activity_types ?? [])
            .some((ageGroupActivityType) => activityTypes.find(
              (activityType) => ageGroupActivityType.toLowerCase() === activityType.name.toLowerCase()),
            ),
          );
          return filteredAgeGroupsForActivityTypes.sort((a, b) => a.order - b.order);
        }),
      );

      this.availableRanges$ = combineLatest([
        this.activityAreaOfDevelopment$, this.allRangesOfDevelopment$,
      ]).pipe(
        takeUntil(this.countryCodeChange$),
        map(([selectedDevelopmentAreas, allRangesOfDevelopment]) => {
          if (!selectedDevelopmentAreas || selectedDevelopmentAreas.length === 0) {
            return [];
          }

          const selectedDevelopmentAreaIds = selectedDevelopmentAreas.map((selectedArea) => selectedArea.id);

          // Only include the items if they are in the list of selected development area ids.
          // Only include the items if they are in the list of selected development area ids.
          const availableRanges = allRangesOfDevelopment.filter((rangeOfDevelopment) => selectedDevelopmentAreaIds.includes(rangeOfDevelopment.area_of_development_id));

          // return the named ranges sorted by the area id so that we are sure the different ranges are grouped together
          //
          return availableRanges.sort((namedRangeA, namedRangeB) => namedRangeA?.area_of_development_id - namedRangeB?.area_of_development_id);
        }),
      );

      this.filteredKvsMethods$ = combineLatest([this.activityActivityTypes$, this.allKvsMethods$, this.isAdmin$, this.hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$]).pipe(
        takeUntil(this.countryCodeChange$),

        map(([selectedActivityTypes, kvsMethods, isAdmin, hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree]) => {
          if (isAdmin || hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree) {
            return kvsMethods.filter((kvsMethod) => some(selectedActivityTypes, (activityType) => (kvsMethod.activity_types ?? []).includes(activityType.name)));
          }
          return [];
        }));

      this.kvsMethodsToShow$ = combineLatest([this.filteredKvsMethods$, this.activityAgeGroups$]).pipe(
        distinctUntilChanged((prev, curr) => isEqual(prev[0], curr[0]) && isEqual(prev[1], curr[1])),
        debounceTime(25),
        tap(([methods, ageGroups]) => {
          this.setKvsControls(methods, ageGroups);
        }),
        map(([kvsMethods]) => {
          return kvsMethods;
        }),
      );

      this.showKvsMethods$ = this.kvsMethodsToShow$.pipe(
        map((kvsMethods) => kvsMethods.length > 0),
      );
    });

    //  If the permission service tells us the current activity can be edited, enable the form. Otherwise, disable it.
    //
    this.$permission.hasOUWritePermissions$.pipe(takeUntil(this.destroy$)).subscribe((allowedToEdit) => {
      const formOpts = { onlySelf: true, emitEvent: false };

      const markAsPristine = this.form.pristine;

      if (allowedToEdit) {
        this.form.enable(formOpts);
      } else {
        this.form.disable(formOpts);
      }

      if (markAsPristine) {
        this.form.markAsPristine();
      }
    });

    this.coverImage$ = this.activityDetails$.pipe(
      takeUntil(this.destroy$),
      filter((value) => !isNil(value)),
      map((activity: IActivity) => ({
        uuid: activity.media_uuid,
        description: undefined,
      } as IMediaItem)),
    );

    this.initialized.emit(this.form);
  }

  /** Destroy all subscriptions, leave no subscribers.
   */
  ngOnDestroy(): void {
    this.destroy$.next(undefined);
    this.countryCodeChange$.next();
  }

  async openAttachment(attachment: IAttachmentMedia) {
    // eslint-disable-next-line no-undef
    const a = document.createElement('a');

    const mediaAssetUrl = await firstValueFrom(this.assetService.getUrl(attachment.uuid)) as IAssetUrl;

    a.target = '_blank';
    a.href = mediaAssetUrl.url;
    a.download = attachment.filename;
    a.click();
  }

  /**
   * Saves the current activity details, including every section attached to the activity.
   */
  public async saveDetails(statusId?: number, forceUpdate: boolean = false) {
    const activityId = this.activityId$.value;

    // Destructure form values.
    //
    const {
      name, subtitle, duration, preparation, summary, media_uuid: mediaUuid,
    } = this.form.value;

    console.log('save details', mediaUuid);

    const promises = [];

    promises.push(
      this.saveActivityTypes(),
      this.saveActivityLocations(),
      this.saveTags(),
      this.saveAgeGroups(),
      this.saveGroupSizes(),
      this.saveActivityKinds(),
      this.saveAreaOfDevelopment(),
      this.saveRangeOfDevelopment(),
      this.saveKvsMethods(),
    );

    // Save all details in the form.
    //
    const activitySnapshot = await firstValueFrom(this.activityDetails$);

    statusId = isNil(statusId) ? activitySnapshot.status_id : statusId;

    const activity: IActivity = {
      ...activitySnapshot,
      name,
      duration,
      preparation,
      subtitle,
      summary,
      media_uuid: mediaUuid,
      status_id: statusId,
    };

    if (forceUpdate || !isEqual(activity, activitySnapshot)) {
      promises.push(this.activityService.update(activityId, activity));
    }

    await Promise.all(promises);

    this.updated.emit();
  }

  public async saveActivityTypes() {
    const { activityTypes } = this.form.value;
    const currentTypes = this.activityActivityTypes$.value;
    const promises = [];

    const { activityId, addedValues, removedValues } = await this.saveMultipleChoiceOption(activityTypes, currentTypes);

    addedValues.forEach((addedValue) => {
      promises.push(firstValueFrom(this.activityService.addActivityType(activityId, addedValue)));
    });

    removedValues.forEach((removedValue) => {
      promises.push(firstValueFrom(this.activityService.archiveActivityType(activityId, removedValue)));
    });

    await Promise.all(promises);

    this.fetchActivityTypes();
  }

  public async saveActivityLocations() {
    const { location } = this.form.value;
    const currentLocations = this.activityLocations$.value;
    const promises = [];

    const { activityId, addedValues, removedValues } = await this.saveMultipleChoiceOption(location, currentLocations);

    addedValues.forEach((addedValue) => {
      promises.push(firstValueFrom(this.activityService.addActivityLocation(activityId, addedValue)));
    });

    removedValues.forEach((removedValue) => {
      promises.push(firstValueFrom(this.activityService.archiveActivityLocation(activityId, removedValue)));
    });

    await Promise.all(promises);

    this.fetchActivityLocations();
    this.updated.emit();
  }

  public async saveAgeGroups() {
    const { agegroups } = this.form.value;
    const { valid } = this.form.controls.agegroups;

    if (!valid) {
      console.error('Invalid value for age groups');
      return;
    }
    const currentAgeGroups = this.activityAgeGroups$.value;
    const promises = [];

    const { activityId, modifiedValues, removedValues } = await this.saveWeightedValueOption(agegroups, currentAgeGroups);

    modifiedValues.forEach((modifiedValue) => {
      promises.push(firstValueFrom(this.activityService.addAgeGroup(activityId, modifiedValue.id, modifiedValue.weight)));
    });

    removedValues.forEach((removedValue) => {
      promises.push(firstValueFrom(this.activityService.archiveAgeGroup(activityId, removedValue)));
    });

    await Promise.all(promises);

    this.fetchActivityAgeGroups();
    this.updated.emit();
  }

  public async saveGroupSizes() {
    const { groupsizes } = this.form.value;
    const currentGroupSizes = this.activityGroupSizes$.value;
    const promises = [];

    const { activityId, addedValues, removedValues } = await this.saveMultipleChoiceOption(groupsizes, currentGroupSizes);

    addedValues.forEach((addedValue) => {
      promises.push(firstValueFrom(this.activityService.addGroupSize(activityId, addedValue)));
    });

    removedValues.forEach((removedValue) => {
      promises.push(firstValueFrom(this.activityService.archiveGroupSize(activityId, removedValue)));
    });

    await Promise.all(promises);

    this.fetchActivityGroupSizes();
    this.updated.emit();
  }

  public async saveActivityKinds() {
    const { activitykinds } = this.form.value;
    const { valid } = this.form.controls.agegroups;

    if (!valid) {
      console.error('Invalid value for activity kinds');
      return;
    }

    const currentActivityKinds = this.activityActivityKinds$.value;
    const promises = [];

    const { activityId, modifiedValues, removedValues } = await this.saveWeightedValueOption(activitykinds, currentActivityKinds);

    modifiedValues.forEach((modifiedValue) => {
      promises.push(firstValueFrom(this.activityService.addActivityKind(activityId, modifiedValue.id, modifiedValue.weight)));
    });

    removedValues.forEach((removedValue) => {
      promises.push(firstValueFrom(this.activityService.archiveActivityKind(activityId, removedValue)));
    });

    await Promise.all(promises);

    this.fetchActivityKinds();
    this.updated.emit();
  }

  public async saveAreaOfDevelopment() {
    const { developmentareas } = this.form.value;
    const { valid } = this.form.controls.agegroups;

    if (!valid) {
      console.error('Invalid value for area of development');
      return;
    }

    const currentDevelopmentAreas = this.activityAreaOfDevelopment$.value;
    const promises = [];

    const { activityId, modifiedValues, removedValues } = await this.saveWeightedValueOption(developmentareas, currentDevelopmentAreas);

    modifiedValues.forEach((modifiedValue) => {
      promises.push(firstValueFrom(this.activityService.addAreaOfDevelopment(activityId, modifiedValue.id, modifiedValue.weight)));
    });

    removedValues.forEach((removedValue) => {
      promises.push(firstValueFrom(this.activityService.archiveAreaOfDevelopment(activityId, removedValue)));
    });

    await Promise.all(promises);

    this.fetchActivityAreasOfDevelopment();
    this.updated.emit();
  }

  public async saveRangeOfDevelopment() {
    const { developmentranges } = this.form.value;
    const currentDevelopmentRanges = this.activityRangesOfDevelopment$.value;
    const promises = [];

    const { activityId, addedValues, removedValues } = await this.saveMultipleChoiceOption(developmentranges, currentDevelopmentRanges);

    addedValues.forEach((addedValue) => {
      promises.push(firstValueFrom(this.activityService.addRangeOfDevelopment(activityId, addedValue)));
    });

    removedValues.forEach((removedValue) => {
      promises.push(firstValueFrom(this.activityService.archiveRangeOfDevelopment(activityId, removedValue)));
    });

    await Promise.all(promises);

    this.fetchActivityDevelopmentRanges();
    this.updated.emit();
  }

  public async saveTags() {
    const { tags } = this.form.value;
    const currentTags = this.activityTags$.value;
    const promises = [];

    const { activityId, addedValues, removedValues } = await this.saveMultipleChoiceOption(tags, currentTags);

    addedValues.forEach((addedValue: number) => {
      promises.push(firstValueFrom(this.activityService.addActivityTag(activityId, addedValue)));
    });

    removedValues.forEach((removedValue: number) => {
      promises.push(firstValueFrom(this.activityService.archiveActivityTag(activityId, removedValue)));
    });

    await Promise.all(promises);

    this.fetchActivityTags();
    this.updated.emit();
  }

  public async saveKvsMethods() {
    const currentKvsMethods = this.activityObservationPoints$.value;
    const promises = [];
    const formObservationPoints: { code: string; weight: number; }[] = [];

    for (const observationCode of Object.keys((<UntypedFormGroup> this.form.get('kvsMethods')).controls)) {
      const control = this.getKvsControl(observationCode);
      if (control.enabled) {
        formObservationPoints.push({
          code: observationCode,
          weight: control.value,
        });
      }
    }

    const { activityId, modifiedValues, removedValues } = await this.saveWeightedValueOption(formObservationPoints, currentKvsMethods, 'code');

    const showKvsMethods = await firstValueFrom(this.showKvsMethods$);

    if (showKvsMethods) {
      // we do have the kdv type selected and thus we need to save whats added/modified/removed
      //
      modifiedValues.forEach((modifiedValue: any) => {
        if (modifiedValue.weight > 0) {
          promises.push(firstValueFrom(this.activityService.addKvsObservationPoint(activityId, modifiedValue.id, modifiedValue.weight)));
        } else {
          promises.push(firstValueFrom(this.activityService.archiveKvsObservationPoint(activityId, modifiedValue.id)));
        }
      });

      removedValues.forEach((removedValue: string) => {
        promises.push(firstValueFrom(this.activityService.archiveKvsObservationPoint(activityId, removedValue)));
      });
    } else {
      // we don't have kdv selected anymore so we need to remove all existing methods so they don't show up in Konnect
      //
      for (const kvsMethod of currentKvsMethods) {
        promises.push(firstValueFrom(this.activityService.archiveKvsObservationPoint(activityId, kvsMethod.code)));
      }
    }

    await Promise.all(promises);

    this.fetchActivityKvsMethods(true);
    this.updated.emit();
  }

  async saveMultipleChoiceOption(currentFormValues: any[], currentValues: any[]) {
    const activityId = this.activityId$.value;

    const addedValues = [];
    const removedValues = [];

    // find the newly added values
    //
    const newlyAddedValues = currentFormValues
      .filter((currentFormValue) => !currentValues.find((currentValue) => {
        const mappedCurrentValue = currentValue?.id ?? currentValue;
        const mappedFormValue = currentFormValue?.id ?? currentFormValue;
        return isEqual(mappedCurrentValue, mappedFormValue);
      }));
    // for each newly added value in the form to the addedValues array
    //
    newlyAddedValues.forEach((addedValue) => {
      addedValues.push(addedValue?.id ?? addedValue);
    });

    // filter out the removed values
    //
    const removedCurrentValues = currentValues
      .filter((currentValue) => !currentFormValues.find((formValue) => {
        const mappedCurrentValue = currentValue?.id ?? currentValue;
        const mappedFormValue = formValue?.id ?? formValue;
        return isEqual(mappedCurrentValue, mappedFormValue);
      }));

    // for each removed value call the add it to the removedValues array
    //
    removedCurrentValues.forEach((removedValue) => {
      removedValues.push(removedValue?.id ?? removedValue);
    });

    return {
      activityId,
      addedValues,
      removedValues,
    };
  }

  async saveWeightedValueOption(currentFormValues: any[], currentValues: any[], propertyAsId: string = 'id') {
    const activityId = this.activityId$.value;
    const modifiedValues = [];
    const removedValues = [];

    // find the newly added values and values with a modified weight
    //
    const newlyAddedOrChangedValues = currentFormValues
      .filter(
        (currentFormValue) => {
          const value = currentValues.find((currentValue) => isEqual(currentValue[propertyAsId], currentFormValue[propertyAsId]));
          if (value) {
            if (currentFormValue.weight !== value.weight) {
              return true;
            }
            return false;
          }
          return true;
        },
      );
    // for each modified value in the form to the addedValues array
    //
    newlyAddedOrChangedValues.forEach((addedValue) => {
      modifiedValues.push({ id: addedValue[propertyAsId], weight: addedValue.weight });
    });

    // filter out the removed values
    //
    const removedCurrentValues = currentValues
      .filter((currentValue) => !currentFormValues.find((formValue) => isEqual(formValue[propertyAsId], currentValue[propertyAsId])));

    // for each removed value call the add it to the removedValues array
    //
    removedCurrentValues.forEach((removedValue) => {
      removedValues.push(removedValue[propertyAsId]);
    });

    return {
      activityId,
      modifiedValues,
      removedValues,
    };
  }

  // #region COVER IMAGE =========================\
  /**
   * Selects an exiting image to the activity. Replaces the current image if one is present.
   * @param selectedImage an image that was selected
   */
  updateFeaturedImage(selectedImage: IMediaItem) {
    const currentUuid = this.form.get('media_uuid').value;
    this.form.get('media_uuid').setValue(selectedImage.uuid, { emitEvent: true });
    this.saveDetails(undefined, currentUuid === selectedImage.uuid);

    this.cd.detectChanges();
  }

  /**
   * Adds an image to the activity. Replaces the current image if one is present.
   * @param uploadedImage an image that was uploaded to the Doenkids media API.
   */
  async addFeaturedImage(uploadedImage: IUploadResponse) {
    const currentUuid = this.form.get('media_uuid').value;
    this.form.get('media_uuid').setValue(uploadedImage.uuid);
    this.saveDetails(undefined, currentUuid === uploadedImage.uuid);
  }
  //
  // #endregion
  // =============================================/

  // #region ATTACHMENTS =========================\
  //
  /**
   * Adds an attachment to the activity.
   * @param selectedAttachment an attachment that was uploaded to the DoenKids media API.
   */
  async addAttachment(selectedAttachment: IAttachmentMedia) {
    const activityId = await firstValueFrom(this.activityId$);

    await firstValueFrom(this.activityService.addAttachment(activityId, selectedAttachment.id));
    this.fetchActivityAttachments();
    this.updated.emit();
    this.attachmentsUpdated.emit();
  }

  async removeAttachment(attachment: IAttachmentMedia) {
    const activityId = await firstValueFrom(this.activityId$);

    await firstValueFrom(this.activityService.archiveAttachment(activityId, attachment.media_id));
    this.fetchActivityAttachments();
    this.updated.emit();
    this.attachmentsUpdated.emit();
  }

  dropAttachment(event: CdkDragDrop<IAttachmentMedia[]>) {
    const currentArray = this.activityAttachments$.value;

    const itemThatMoved = currentArray[event.previousIndex];
    const itemToPlaceItAfterOrBefore = currentArray[event.currentIndex];
    const orderOfItemToPlaceItAfterOrBefore = itemToPlaceItAfterOrBefore?.order ?? 10;
    const newOrder = event.previousIndex < event.currentIndex ? orderOfItemToPlaceItAfterOrBefore + 1 : orderOfItemToPlaceItAfterOrBefore - 1;

    this.$attachment.reorder(this.activityId$.value, itemThatMoved.media_id, newOrder).then(() => {
      this.fetchActivityAttachments();

      moveItemInArray(currentArray, event.previousIndex, event.currentIndex);

      this.activityAttachments$.next(currentArray);
    }).catch((e) => {
      console.error(e);
      this.$i18nToast.error(_('activity.attachments.reorder.failed'));
    });
  }
  //
  // #endregion
  // =============================================/

  setStep(index: number) {
    this.step = index;
  }

  compareWith(firstObj: { id?: number }, secondObj: { id?: number }) {
    return firstObj.id === secondObj.id;
  }

  async fetchActivityKinds() {
    const activityActivityKinds = await firstValueFrom(this.activityService.activityKind(this.activityId$.value)) as IActivityKindListResponse;
    this.checkValidityOfValues(activityActivityKinds.items);
    this.form.get('activitykinds').setValue([...activityActivityKinds.items]);
    this.activityActivityKinds$.next(activityActivityKinds.items);
  }

  async fetchActivityAreasOfDevelopment() {
    const activityDevelopmentAreas = await firstValueFrom(this.activityService.areaOfDevelopment(this.activityId$.value)) as IAreaOfDevelopmentListResponse;
    this.checkValidityOfValues(activityDevelopmentAreas.items);
    this.form.get('developmentareas').setValue([...activityDevelopmentAreas.items]);
    this.activityAreaOfDevelopment$.next(activityDevelopmentAreas.items);
  }

  async fetchActivityDevelopmentRanges() {
    const activityDevelopmentRanges = await firstValueFrom(this.activityService.activityRangeOfDevelopment(this.activityId$.value)) as IRangeOfDevelopmentListResponse;
    this.form.get('developmentranges').setValue([...activityDevelopmentRanges.items]);
    this.activityRangesOfDevelopment$.next(activityDevelopmentRanges.items);
  }

  async fetchActivityGroupSizes() {
    const activityGroupSizes = await firstValueFrom(this.activityService.groupSize(this.activityId$.value)) as IGroupSizeListResponse;
    this.form.get('groupsizes').setValue([...activityGroupSizes.items]);
    this.activityGroupSizes$.next(activityGroupSizes.items);
  }

  async fetchActivityAgeGroups() {
    const activityAgeGroups = await firstValueFrom(this.activityService.ageGroup(this.activityId$.value)) as IAgeGroupListResponse;
    this.form.get('agegroups').setValue([...activityAgeGroups.items]);
    this.activityAgeGroups$.next(activityAgeGroups.items);
  }

  async fetchActivityTypes() {
    const activityTypes = await firstValueFrom(this.activityService.activityType(this.activityId$.value)) as IActivityTypeListResponse;
    this.form.get('activityTypes').setValue(activityTypes.items);
    this.activityActivityTypes$.next(activityTypes.items);
  }

  async fetchActivityLocations() {
    const activityLocations = await firstValueFrom(this.activityService.activityLocation(this.activityId$.value)) as ILocationListResponse;
    this.form.get('location').setValue(activityLocations.items);
    this.activityLocations$.next(activityLocations.items);
  }

  async fetchActivityTags() {
    const activityTags = await firstValueFrom(this.activityService.activityTag(this.activityId$.value)) as ITagListResponse;
    this.form.get('tags').setValue([...activityTags.items]);
    this.activityTags$.next(activityTags.items);
  }

  async fetchActivityAttachments() {
    const activityAttachments = await firstValueFrom(this.activityService.attachment(this.activityId$.value)) as IAttachmentMediaListResponse;
    const sortedItems = activityAttachments.items.sort((attachmentA, attachmentB) => attachmentA.order - attachmentB.order);
    this.form.get('attachments').setValue(sortedItems);
    this.activityAttachments$.next(sortedItems);
  }

  private itemIsWithinAgeGroups(item: IKvsObservationPointDetails | IKvsMethodWithObservationPoints | IKvsAreaOfDevelopmentSubsection, ageGroups: IAgeGroup[]) {
    return ageGroups.some(({ age_from, age_to }) => {
      return age_from <= item.max_age_months && age_to >= item.min_age_months;
    });
  }

  private createAndAddNewControlForObservationPoint(observationPointNode: IKvsMethodNode, kvsControls: UntypedFormGroup) {
    const newControl = new UntypedFormControl(observationPointNode.weight);

    newControl.valueChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged(isEqual)).subscribe((newValue) => {
      this.sliderChanged(newControl, newValue);
      observationPointNode.weight = newValue;
    });

    newControl.disable();
    kvsControls.addControl(observationPointNode.code, newControl);
  }

  async setKvsControls(kvsMethods: IKvsMethodWithObservationPoints[], activityAgeGroups: IAgeGroup[]) {
    this.kvsGroupChange$.next();
    const methods: IKvsMethodNode[] = [];
    const kvsControls: UntypedFormGroup = new UntypedFormGroup({});
    const defaultWeight = 0;

    const filteredObservationPoints: IKvsObservationPointDetails[] = [];
    for (const kvsMethod of kvsMethods) {
      if (this.itemIsWithinAgeGroups(kvsMethod, activityAgeGroups)) {
        const newMethodNode: IKvsMethodNode = {
          code: kvsMethod.code,
          name: kvsMethod.name,
          children: [],
        };
        for (const subsection of kvsMethod.subsections) {
          if (this.itemIsWithinAgeGroups(subsection, activityAgeGroups)) {
            const newMethodNodeChild: IKvsMethodNode = {
              code: subsection.code,
              name: subsection.name,
              children: [],
            };

            if (subsection.subsections) {
              for (const disciplineSubsection of subsection.subsections) {
                if (this.itemIsWithinAgeGroups(disciplineSubsection, activityAgeGroups)) {
                  const newMethodNodeSubsection: IKvsMethodNode = {
                    name: disciplineSubsection.name,
                    children: [],
                  };
                  for (const disciplineObservationPoint of (disciplineSubsection.observationPoints ?? [])) {
                    if (this.itemIsWithinAgeGroups(disciplineObservationPoint, activityAgeGroups)) {
                      const newMethodNodeSubsectionChild: IKvsMethodNode = {
                        code: disciplineObservationPoint.code,
                        name: disciplineObservationPoint.title,
                        weight: defaultWeight,
                        instructions: disciplineObservationPoint.instructions,
                        children: [],
                      };
                      filteredObservationPoints.push(disciplineObservationPoint);
                      newMethodNodeSubsection.children.push(newMethodNodeSubsectionChild);
                      this.createAndAddNewControlForObservationPoint(newMethodNodeSubsectionChild, kvsControls);
                    }
                  }

                  if (newMethodNodeSubsection.children.length > 0) {
                    newMethodNodeChild.children.push(newMethodNodeSubsection);
                  }
                }
              }
            }

            if (subsection.observationPoints) {
              for (const subsectionObservationPoint of subsection.observationPoints) {
                if (this.itemIsWithinAgeGroups(subsectionObservationPoint, activityAgeGroups)) {
                  const newMethodNodeChildChild: IKvsMethodNode = {
                    code: subsectionObservationPoint.code,
                    name: subsectionObservationPoint.title,
                    weight: defaultWeight,
                    instructions: subsectionObservationPoint.instructions,
                    children: [],
                  };
                  filteredObservationPoints.push(subsectionObservationPoint);
                  newMethodNodeChild.children.push(newMethodNodeChildChild);
                  this.createAndAddNewControlForObservationPoint(newMethodNodeChildChild, kvsControls);
                }
              }
            }

            if (newMethodNodeChild.children.length > 0) {
              newMethodNode.children.push(newMethodNodeChild);
            }
          }

        }

        if (newMethodNode.children.length > 0) {
          methods.push(newMethodNode);
        }
      }
    }

    kvsControls.valueChanges.pipe(takeUntil(this.kvsGroupChange$)).subscribe((value) => {
      const enabledKeys = Object.keys(value);
      const keysWithWeight = enabledKeys.filter((key) => value[key] > 0);
      this.activeKvsControlCount$.next(keysWithWeight.length);
      const mappedKeys = enabledKeys.map((key) => {
        const currentNode = this.allObservationPoints$.value.find((node) => node.code === key);
        return currentNode;
      });
      const enabledKeysWithDescription = groupBy(mappedKeys, (key) => {
        if (key.area_of_development_discipline) {
          return `${key.area_of_development} → ${key.area_of_development_discipline}`;
        }
        return key.area_of_development;
      });
      this.activeKvsMethodNames$.next(enabledKeysWithDescription);
    });

    this.allObservationPoints$.next(filteredObservationPoints);
    this.form.controls.kvsMethods = kvsControls;
    this.dataSource.data = methods;
    this.fetchActivityKvsMethods();
  }

  mapWeightToKvsControl(code: string, weight: number) {
    const kvsControl = (<UntypedFormGroup> this.form.get('kvsMethods')).get(code);

    if (kvsControl) {
      kvsControl.setValue(weight);
    }

    this.mapWeightToKvsMethods(this.dataSource.data, { code, weight });
  }

  mapWeightToKvsMethods(methods: IKvsMethodNode[], methodCode: { code: string, weight: number }) {
    for (const method of methods) {
      if (method.code === methodCode.code) {
        method.weight = methodCode.weight;
      }
      if (method.children.length > 0) {
        this.mapWeightToKvsMethods(method.children, methodCode);
      }
    }
  }

  async fetchActivityKvsMethods(forceSetActivityMethods: boolean = false) {
    let activityKvsMethods: IWeightedKvsObservationPointDetails[] = this.activityObservationPoints$.value;

    if (!this.activityObservationPoints$.value || forceSetActivityMethods) {
      activityKvsMethods = (await firstValueFrom(this.activityService.kvsObservationPoints(this.activityId$.value, 1000, 0)) as IKvsObservationPointListResponse)?.items ?? [];
      this.activityObservationPoints$.next(activityKvsMethods);
    }
    const methodCodes = activityKvsMethods.map((kvsMethod) => ({ code: kvsMethod.code, weight: kvsMethod.weight ?? 0 }));
    for (const methodCode of methodCodes) {
      this.mapWeightToKvsControl(methodCode.code, methodCode.weight);
    }
    for (const kvsControlCode of Object.keys((<UntypedFormGroup> this.form.get('kvsMethods')).controls)) {
      const isActive = methodCodes.find((methodCode) => methodCode.code === kvsControlCode);
      const control = this.getKvsControl(kvsControlCode);

      if (isActive) {
        control.enable();
        control.setValue(isActive.weight);
      } else {
        control.disable();
        control.setValue(0);
      }
    }
  }

  kvsMethodToggle(code: string) {
    const observationPointControl = this.getKvsControl(code);

    if (observationPointControl.enabled) {
      observationPointControl.disable();
      observationPointControl.setValue(0);
    } else if (observationPointControl.disabled) {
      observationPointControl.enable();
      observationPointControl.setValue(100);
    }
  }

  getKvsControl(code: string): UntypedFormControl {
    return (<UntypedFormControl> (<UntypedFormGroup> this.form.get('kvsMethods')).get(code));
  }

  goToKvsPoint(code: string) {
    let htmlElements = this.document.getElementsByClassName(code);

    if (htmlElements.length === 0) {
      // the element is not expanded yet. therefore we expand the parent nodes ourselves
      // and then retry the getElementsByClass
      //
      for (const node of this.dataSource.data) {
        const hasChildNode = this.expandParentsOfNode(node, code);
        if (hasChildNode) {
          // eslint-disable-next-line @typescript-eslint/no-loop-func
          this.treeControl.dataNodes.forEach((dataNode: IKvsMethodFlatNode) => {
            if (dataNode.expandable && dataNode.code === node.code) {
              this.treeControl.expand(dataNode);
            }
          });
          break;
        }
      }
      htmlElements = this.document.getElementsByClassName(code);
    }

    if (htmlElements.length > 0) {
      htmlElements[0].scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
    }
  }

  expandParentsOfNode(node: IKvsMethodNode, code: string) {
    let hasChildNode = false;
    if (node.code === code) {
      // we have found our node
      //
      hasChildNode = true;
    }
    if (node.children) {
      for (const childNode of node.children) {
        hasChildNode = this.expandParentsOfNode(childNode, code);
        if (hasChildNode) {
          this.treeControl.dataNodes.forEach((dataNode: IKvsMethodFlatNode) => {
            if (dataNode.expandable && dataNode.code === childNode.code) {
              this.treeControl.expand(dataNode);
            }
          });
          break;
        }
      }
    }

    return hasChildNode;
  }

  sliderClicked(disabled: boolean, code: string) {
    if (disabled) {
      const sliderControl = this.getKvsControl(code);
      sliderControl.enable();
      sliderControl.setValue(100);
    }
  }

  sliderChanged(control: UntypedFormControl, value: number) {
    if (value === 0) {
      control.disable({ emitEvent: false });
    } else if (control.disabled) {
      control.enable({ emitEvent: false });
    }
  }

  async openInfoWindow($event: MouseEvent, type: string, dialogContent?: { title: string, description: string }) {
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }

    this.dialog.open(InfoDialogComponent, {
      width: '400px',
      minWidth: '320px',
      data: {
        title: dialogContent?.title || this.$translateService.instant(`activity.${type}.dialog.title`),
        description: dialogContent?.title || this.$translateService.instant(`activity.${type}.dialog.description`),
      },
    });
  }

  /**
   * Checks whether the weighted values are valid. If invalid, tries to divide 100 across all values and returns false.
   * @param values weighted value array
   * @returns boolean
   */
  checkValidityOfValues(validityValues): boolean {
    const total = validityValues.reduce((acc, curr) => (curr.weight + acc), 0);

    if (total > 100) {
      validityValues.forEach((value) => {
        value.weight = 0;
      });
      return false;
    }
    return true;
  }

  filterKvsMethods(selectedActivityTypes: IActivityType[], kvsMethods: IKvsMethod[], isAdmin: boolean, hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree: boolean) {
    if (isAdmin || hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree) {
      return kvsMethods.filter((kvsMethod) => some(selectedActivityTypes, (activityType) => (kvsMethod as any).activity_types.includes(activityType.name)));
    }
    return [];
  }
}
