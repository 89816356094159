import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject, ViewEncapsulation } from '@angular/core';

import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
/**
 * Dialog Overview
 */
@Component({
  selector: 'app-confirmation-dialog-with-data',
  templateUrl: 'confirmation-dialog-with-data.component.html',
  styleUrls: ['confirmation-dialog-with-data.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ConfirmationDialogWithDataComponent {
  title: string;

  description: SafeHtml;

  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogWithDataComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { title: string; description: string; hideCancel?: boolean },
    private sanitizer: DomSanitizer,
  ) {
    this.description = this.sanitizeHTML(data.description);
  }

  // Method to sanitize the HTML content
  sanitizeHTML(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  confirm() {
    this.dialogRef.close('confirm');
  }
}
